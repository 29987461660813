@import "../../Variables.scss";


.container {
  padding: 8px 8px 8px 8px;
}

.inner {
}

.row {
  display: flex; flex-direction: column; align-items: stretch;
}

@media only screen and (min-width: 600px) {
  .row {
    display: flex; flex-direction: row; align-items: stretch; flex-wrap: nowrap;
  }
}

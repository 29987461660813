@import "../../Variables.scss";

.page {
  // background-color: $color_primary_lightest;
  display:flex; flex-direction: column; // justify-content: center;
  height: 100%;
}

.page_header,
.page_footer {
  display:flex; flex-direction: column; //justify-content: center;
}


.page_header {
  display: flex; flex-direction: column; align-items: center;
  
  .page_header_inner {
    
    background-color: white;
    width: 100%;
    max-width: $max_width;
    display:flex; flex-direction: row; justify-content: flex-start; align-items: center;
  
    .menu_toggle {
      font-size: 20px;
      background-color:$color_primary;
      color: white;
      padding: 8px;
    }

    .page_header_title {
      white-space: nowrap;
      a {
        text-decoration: none;
        color: $color_text;
      }
      padding-left: 8px;
      color: $color_text;
      .large {
        font-size: $fontsize_large;
      }
      .small {
        font-size: $fontsize_small;
        overflow: hidden;
      }
    }
  }
}

.page_footer {
  display: flex; flex-direction: column; align-items: center;
  // background-color: $color_primary;
  .page_footer_inner {
    width: 100%;
    max-width: $max_width;

    .page_footer_content {
      padding: 32px 8px 32px 0;
      background-color: $color_primary;
      text-align: center;
      .logo {
        img {
          width: 100px;
          height:auto;
        }
      }
      .address, .web, .phone, .email {
        color:white;
        padding:2px 0 2px 0;
        a {
          text-decoration: none;
          color:white;
        }
      }

    }
    
  }

}


.menu {
  position: fixed; top:0; left:0; right:0; bottom:0;
  display: flex; flex-direction: row; justify-content: center;
  
  background-color: rgba(255, 255, 255, 0.5);
  .menu_inner {
    width: 100%;
    max-width: $max_width;

    .menu_inner_content {
      background-color: $color_primary;
      padding-bottom: 32px;

      .close {
        font-size: 20px;
        color: white;
        padding: 8px;
      }
      .menu_item {
        cursor: pointer;
        color: white;
        display:flex; flex-direction: row; align-items: center;
        padding:8px 0 8px 0;
        font-size: $fontsize_large;
        .icon {
          width: 32px;
          text-align: center;
        }
        .title {
          padding: 0 8px 0 8px;
        }
      }
    }
    
  }
  
}
@import "../../Variables.scss";

.container {
  padding:2px;
  .inner {
    label {
      .label {
        color: $color_primary;
        font-size: $fontsize_small;
        padding: 0 0 4px 0;
      }
      textarea {
        width: 100%;
        outline: none;
        padding: 4px;
        font-size: $fontsize_regular;
      }
    }
  }
}
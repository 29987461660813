@import "../../Variables.scss";

.container {
  width: 100%;
  background-color: white;
  position:sticky;
  top: 0;
  
  .inner {
    padding: 8px;
    display:flex; flex-direction:row; align-items: center;
    .divider,
    .parent,
    .location {
      display: flex; flex-direction:row;align-items: center;
      padding:0 4px 0 4px;
      a {
        color:$color_secondary;
        text-decoration: none;
      }
      .icon {
        padding-right: 4px;
      }
      .link {
        display: flex; flex-direction:row;align-items: center;
      }
    }
    
    .divider,
    .location {
      color: $color_text; 
    }
  }

  
}



@import "../../Variables.scss";

.container {
  padding: 2px;
  .label {
    color: $color_primary;
    padding-bottom: 4px;
    font-size: $fontsize_small;
  }
  .text {
    padding-bottom: 4px;
    font-size: $fontsize_regular;
  }
}
@import "../../Variables.scss";

/** container ***************/
.container {
  display: flex; flex-direction: column;
  justify-content: center; align-items: center;

  position: fixed;
  top:0; right:0; bottom:0; left:0;
  
  z-index: 9999999999; // TODO yeah ... happyfox forces us to use a z-index that high

  //background-color: $color_primary_transparent;
  background-color: rgba(255,255,255,0.9);

  // makes the rotateY() of .inner look 3D
  perspective: 1000px;
}



/** inner *****************/
.inner {
  max-width: 90%;
  max-height: 90%;
  overflow-y: auto;
  display: inline-block;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  
  // hide scrollbar
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.inner::-webkit-scrollbar {
  // hide webkit scrollbar
  display: none;
}

/** heading ****************/
.heading {
  border:1px solid $color_secondary;
  background-color: $color_secondary; // rgba(255, 255, 255, 0.8);
  position: sticky;
  top:0;

  .heading_title {
    padding:16px 48px 16px 16px;
    font-size: $fontsize_large;
    font-weight: 500;
    color: white;
  }
  .heading_close {
    position: absolute; top:0; right:0;
    display:flex; flex-direction: row;
    justify-content: center; align-items: center;
    padding: 16px;
    cursor: pointer;
    font-size: $fontsize_large;
    color:white;
  }
}

/** content **************/
.content {
  padding:16px;
}


@keyframes fadeIn {
  0% {
      opacity: 0;
      transform: scale(0);
      //transform: rotateY(90deg);
  }
  100% {
      opacity: 1;
      transform: scale(1);
      //transform: rotateY(0deg);
  }
}
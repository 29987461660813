@import "../../Variables.scss";

.container {
  .text {
    text-align: center;
    font-size:16px;
  }

  .line {
    margin:4px 0 4px 0;
    height: 4px;
    width: 100%;
    position: relative;
    overflow: hidden;
    background-color: #ddd;
  }
  .line:before{
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    // https://cssgradient.io/
    //background: rgb(9,222,35);
    
    background: linear-gradient(90deg, rgba(9,222,35,1) 0%, rgba(143,0,255,1) 100%);

    background: linear-gradient(90deg, $color_primary_lightest 0%, $color_primary 100%);
    animation: c_loading_keyframes 2s linear infinite;
  }

  @keyframes c_loading_keyframes {
    from {left: -200px; width: 30%;}
    50% {width: 30%;}
    70% {width: 70%;}
    80% { left: 50%;}
    95% {left: 120%;}
    to {left: 100%;}
  }
}



  
@import "../../Variables.scss";

.container {
  display:flex; flex-direction: row;
  color: $color_intent_info;
  .text {
    //color: $color_text;
  }
  .icon { 
    padding-right: 4px;
  }
}
@import "../../Variables.scss";

.container {
  position: absolute;
  top: 0; left:0; bottom:0; right:0;
  background-color: $color_primary_lightest;
  display: flex; flex-direction:row; justify-content:center; align-items:center;

  .inner {
    text-align: center;
    width:400px;
    background-color: white;
    .logo {
      padding: 32px;
      background-color: $color_primary;
      img {
        width: 180px;
      }
      .heading {
        color: white;
        padding-top:8px;
        font-size:16px;
      }
    }
    .input {
      padding: 16px;
      input[type=email] {
        width: 90%;
        padding: 8px;
        font-size: 20px;
        text-align: center;
        outline: none;
      }
      .email_invalid {
        padding: 8px 0 8px 0;
        font-size: 18px;
        color: red;
      }
    }
    
    .error, 
    .success {
      text-align: left;
      padding: 16px;
      font-size: 16px;
      p {
        padding-bottom: 4px;
      } 
    }
    .action {
      padding: 16px;
    }
  }
}
@import "../../Variables.scss";

.container_dashboard {
  span {
    font-size: $fontsize_largest;
    font-weight: $fontweight_heading;
    color:$color_primary;
  }
}


.list {
  margin-bottom: 8px;
  .heading {
    padding-bottom: 4px;
    font-size:  $fontsize_large;
    color: $color_secondary;
  }

  table {
    th {
      color: $color_primary;
    }
    td,th {
      text-align: left;
      padding: 4px;
    }
    td.right,
    th.right {
      text-align: right;
    }
  }
  table.details {
    background-color: white;
  }
}
@import "../../Variables.scss";

.container {
  display:flex; flex-direction: column; align-items: center;
  flex-grow: 1; 

  .inner {
    width: 100%;
    max-width: $max_width;
    background-color: white;

    .content {
      padding: 8px;
    }
  }
}

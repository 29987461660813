@import "../../Variables.scss";

.container {
  .stati {
    display: flex; flex-direction: row; justify-content: center;
    margin: 32px 0 32px 0;
    .status {
      font-size: 20px;
      .status_inner {
        padding: 20px;
        background-color: white;
        color: $color_secondary;
        border: 1px solid $color_secondary;
        transition: background-color 0.5s;
        cursor: pointer;
      }
    }
    .status:first-child {
      .status_inner {
        border-top-left-radius: 50%;
        border-bottom-left-radius: 50%;
        //border-right-color: white;
      }
    }
    .status:last-child {
      .status_inner {
        border-top-right-radius: 50%;
        border-bottom-right-radius: 50%;
      }
    }

    .status.active {
      .status_inner {
        background-color: $color_primary;
        color:white;
      }
    }
    .status.active.rejected {
      .status_inner {
        background-color: $color_status_rejected;
        border-color: $color_status_rejected;
      }
    }
    .status.active.accepted {
      .status_inner {
        background-color: $color_status_accepted;
        border-color: $color_status_accepted;
      }
    }
  }
  .actions {
    display: flex; flex-direction: row; justify-content: center;
  }
}
@import "../../Variables.scss";

.container {
  padding: 8px;

  table {
    th {
      color: $color_primary;
    }
    td,th {
      text-align: left;
      padding: 4px;
    }
    td.right,
    th.right {
      text-align: right;
    }
    td.total {
      color: $color_secondary;
    }
  }
}


@import "./Variables.scss";

div#app {
  position: absolute;
  max-width: 100%;
  top:0; left:0; bottom:0; right:0;
}

a {
  color: $color_secondary;
}
@import "../../Variables.scss";

.container {
  display:flex; flex-direction:row;
  padding-bottom: 8px;
  a {
    color: $color_text;
    text-decoration: none;
  }
  a:hover {
    text-decoration: underline;
    text-decoration-color: $color_primary_light;
    text-decoration-style: dotted;
  }
  .icon {
    padding-right: 4px;
    color: $color_primary;
    font-size: $fontsize_regular;
  }
  .text {
    font-size: $fontsize_regular;
  }
}
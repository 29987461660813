@import "../../Variables.scss";

.container {
  display: flex; flex-direction: row; align-items: center;
  padding: 16px 4px 16px 8px;
  font-size: $fontsize_largest;
  color: $color_text;
  .icon,
  .title {
    padding-right:8px;
  }
}
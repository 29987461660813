$font_regular: 'Nunito', sans-serif;
$font_mono: 'Cutive Mono', monospace;

// maximum width of pages's "viewbox"
$max_width: 1440px;

/** color 
  e.g.: 
  - color: $color_primary;
  - background-color: $color_primary_lightest;
**/
$color_text: rgb(20, 20, 20);
$color_text_secondary: #666666;

$color_primary: #54ac60;
$color_primary_hover: #6dc779;
$color_primary_light: rgb(235, 243, 233);
$color_primary_light_transparent: rgba(235, 243, 233, 0.8);
$color_primary_lightest: rgb(242, 248, 240);
$color_primary_lightest_80: rgb(242, 248, 240, 0.8);

$color_secondary: #7b4997;
$color_secondary_hover: #986eb1;
$color_secondary_light:#a677c2;
$color_secondary_lightest: rgb(242, 239, 247);
$color_secondary_transparent: rgba(123, 73, 151, 0.6);

$color_delete: #FF0000;
$color_no: $color_delete;
$color_yes: $color_primary;
$color_disabled: silver;

$color_intent_info: rgb(81, 105, 216);

$color_status_open: rgb(81, 105, 216);
$color_status_open_light: rgb(135, 152, 231);
$color_status_accepted: rgb(21, 167, 21);
$color_status_accepted_light: rgb(138, 219, 138);
$color_status_rejected: rgb(194, 8, 8);
$color_status_rejected_light: rgb(241, 97, 97);

/** background 
  e.g.: background: $background_gradient;
**/
$background_gradient: linear-gradient(90deg, rgba(123, 73, 151, 1) 0%, rgba(123, 73, 151, 1) 25%, rgba(84, 172, 96, 1) 66%, rgba(84, 172, 96, 1) 100%);
$background_gradient_diagonal: linear-gradient(45deg, rgba(123, 73, 151, 1) 0%, rgba(123, 73, 151, 1) 25%, rgba(84, 172, 96, 1) 66%, rgba(84, 172, 96, 1) 100%);

/** font sizes and weight **/
$fontsize_heading: 22px;
$fontweight_heading: 600;

$fontsize_smallest: 10px;
$fontsize_smaller: 12px;
$fontsize_small: 14px;
$fontsize_regular: 16px;
$fontsize_large: 20px;
$fontsize_largest: 24px;


/**
z-index
1: basic level
2: fixed elements
3: modal
*/
$zindex_1: 0;
$zindex_1a: 100;
$zindex_2: 1000;
$zindex_2a: 1100;
$zindex_3: 2000;
$zindex_3a: 2100;

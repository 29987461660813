@import "../../Variables.scss";

.location {
  padding-bottom: 8px;
  .location_inner {
    border: 1px solid $color_primary;
    padding: 8px;
  }
}

.hint {
  a {
    text-decoration: underline;
    color: $color_primary;
  }
}
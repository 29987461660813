@import "../../Variables.scss";

.container {
  // .tabs {
  //   display: flex; flex-direction: row;
  //   padding-bottom: 8px;
  //   .tab {
  //     padding: 8px;
  //     border-bottom: 4px solid white;
  //     cursor: pointer;
  //   }
  //   .tab.active {
  //     border-color: $color_secondary_light;
  //     transition: border-color 1s;
  //   }
  // }
  .filter {
    display: flex; flex-direction: row;
  }

  
  div.items {
    padding-top: 16px;
    div.no_items_found {
      font-size: $fontsize_regular;
    }
    div.no_items_found.disabled {
      background-color: $color_primary_lightest;
      color: $color_primary_lightest;
    }

    div.item {
      display:flex; flex-direction: row; justify-content: flex-start;
      border: 1px solid $color_primary_lightest;
      border-radius: 4px;
      box-shadow: 0 2px 4px silver;
      
      margin-bottom: 16px;

      .status {
        padding-right:4px;
        color:white;
        padding:8px 4px 0 4px;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .status.open {
        background-color: $color_status_open_light;
      }
      .status.accepted {
        background-color: $color_status_accepted_light;
      }
      .status.rejected {
        background-color: $color_status_rejected_light;
      }
      
      .services_and_action {
        padding: 4px;
        .services {
          .operator {
            a {
              text-decoration: none;
              color: $color_text;
            }
          }
        }
        .action {
          padding-top:8px;
          text-align: left;
        }
      }
    }
  }

}

.container_dashboard {
  span {
    font-size: $fontsize_largest;
    font-weight: $fontweight_heading;
    color:$color_primary;
  }
}
@import "../../Variables.scss";

.container {
  padding: 2px;
  display: inline-block;
  .inner {
    display: flex; flex-direction: row; align-items: center;
    position: relative;
    border-radius: 4px;
    padding: 4px 16px 4px 16px;
    cursor: pointer;
  }
}

.container.size-small {
  font-size: $fontsize_smallest;
}
.container.size-regular {
  font-size: $fontsize_regular;
}
.container.size-large {
  font-size: $fontsize_largest;
  .inner {
    padding: 8px 32px 8px 32px;
  }
}

.container.busy {
  .content {
    color:white;
  }
  .inner {
    background-color: silver !important;
    .spin_container {
      position: absolute; top:0; left:0; right:0; bottom:0;
      display: flex; flex-direction: row; justify-content: center; align-items: center;
      //background-color: rgba(255, 255, 255, 0.5);
      .spin {
        color: $color_primary;
        animation: spin_rotation 2s linear infinite;
      }
    }
  }
}
.container.intent-disabled {
  .inner {
    background-color: silver;
    color:  $color_secondary;
    border:1px solid $color_secondary;
  }
}
.container.intent-ok {
  .inner {
    color:white;
    background-color: $color_secondary;
    border: 1px solid $color_secondary;
  }
}
.container.intent-cancel {
  .inner {
    color: $color_secondary;
    background-color: white;
    border:1px solid $color_secondary;
  }
}

@keyframes spin_rotation {
  0% { 
    color: $color_primary;
    transform: rotate(0deg); 
  }
  50% { 
    color: $color_secondary;
  }
  100% { 
    color: $color_primary;
    transform: rotate(360deg); 
  }
}
@import "../../Variables.scss";

.container {
  border: 1px solid black;
  padding: 8px 4px 8px 4px;
  .title {
    padding-bottom: 8px;
    font-size: $fontsize_large;
  }
  .message {
    font-size: $fontsize_regular;
  }
}

.container.intent-error {
  border-color: red;
  color: red;
}
.container.intent-info {
  border-color: $color_primary;
  color: $color_primary;
  background-color:$color_primary_lightest;
}
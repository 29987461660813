@import "../../Variables.scss";

.container {
  display: flex; flex-direction: column; justify-content: stretch;
  padding: 4px 0 4px 0;
  width: 100%;

  .container_inner {
    padding: 8px;
    background-color: white;
    height: 100%;
    display: flex; flex-direction: column; justify-content:space-between;

    .top {
      .icon {
        color: $color_primary;
        font-size: 32px;
      }
  
      .title {
        padding: 8px 0 16px 0;
        font-size: $fontsize_large;
        font-weight: 500;
      }
      .content {
        padding-bottom: 8px;
      }
    }
    
    .bottom {

      .action {
        text-align: right;
        .action_inner {
          display: inline-block;
          background-color: $color_secondary_lightest;
          border: 1px solid $color_secondary;
          border-radius: 4px;
          color: $color_secondary;
          cursor: pointer;
          padding: 8px 20px 8px 20px;
        }
      }
    }
    
  }
}


@media only screen and (min-width: 500px) {
  .container {
    width: 50%;
    .container_inner {
      height: 100%;
    }
  }
  .container:nth-child(1) {
    padding-right:4px;
  }
  .container:nth-child(2) {
    padding-left:4px;
  }
}